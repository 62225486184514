export function useLoginTracking() {
  const { $gtm, $i18n } = useNuxtApp()

  const commonParams = {
    event: 'login_credentials',
    category: undefined,
    component: 'login-credentials',
    position: undefined,
    language: unref($i18n.locale),
    _clear: true,
  }

  const getLoginPageTracking = () => {
    const commonFilterParams = {
      action: 'view',
      ...commonParams,
    }
    return {
      trackForgotNumberView: () => {
        $gtm.trackEvent({
          label: 'Forgot number?',
          ...commonFilterParams,
        })
      },
      trackForgotPasswordView: () => {
        $gtm.trackEvent({
          label: 'Forgot password or no account?',
          ...commonFilterParams,
        })
      },
    }
  }

  const getForgotFormTracking = () => {
    const commonFilterParams = {
      action: 'request',
      ...commonParams,
    }
    return {
      trackForgotNumberRequest: () => {
        $gtm.trackEvent({
          label: 'Forgot number?',
          ...commonFilterParams,
        })
      },
      trackForgotPasswordRequest: () => {
        $gtm.trackEvent({
          label: 'Forgot password or no account?',
          ...commonFilterParams,
        })
      },
    }
  }

  const getForgotFormSuccessTracking = () => {
    const commonFilterParams = {
      action: 'success',
      ...commonParams,
    }
    return {
      trackForgotNumberSuccess: () => {
        $gtm.trackEvent({
          label: 'Forgot number?',
          ...commonFilterParams,
        })
      },
      trackForgotPasswordSuccess: () => {
        $gtm.trackEvent({
          label: 'Forgot password or no account?',
          ...commonFilterParams,
        })
      },
    }
  }

  return {
    login: getLoginPageTracking(),
    forgotForm: getForgotFormTracking(),
    successForm: getForgotFormSuccessTracking(),
  }
}
